import { DateTime, Settings } from 'luxon'

export abstract class DateUtils {
	public static getFirstDateOfMonth(date: Date): Date {
		date = new Date(date.getTime())
		date.setHours(0, 0, 0, 0)
		date.setDate(1)
		return date
	}

	public static getLastDateOfMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() + 1)
		date.setDate(date.getDate() - 1)
		return date
	}

	public static getFirstDateOfPreviousMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() - 1)
		date.setDate(1)
		return date
	}

	public static getFirstDateOfNextMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() + 1)
		date.setDate(1)
		return date
	}

	public static getLastDateOfNextMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() + 2)
		date.setDate(date.getDate() - 1)
		return date
	}

	public static toIsoStringDatePartOnly(date: Date): string {
		return date.toISOString().substring(0, 10)
	}

	public static getMondayOfWeek(dateInWeek: Date): Date {
		const date = new Date(dateInWeek)
		date.setHours(0, 0, 0, 0)
		while (date.getDay() !== 1) {
			date.setDate(date.getDate() - 1)
		}
		return date
	}
	public static getSundayOfWeek(dateInWeek: Date): Date {
		const date = new Date(dateInWeek)
		date.setHours(0, 0, 0, 0)
		while (date.getDay() !== 0) {
			date.setDate(date.getDate() + 1)
		}
		return date
	}

	public static datePartsEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() === d2.getTime()
	}

	public static dateIsGreater(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() > d2.getTime()
	}

	public static dateIsGreaterOrEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() >= d2.getTime()
	}

	public static dateIsLesserOrEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() <= d2.getTime()
	}

	public static getIsoWeekNumber(date: Date): number {
		/*
			https://bito.ai/resources/javascript-get-week-number-javascript-explained/
		*/
		date = new Date(date.getTime())
		date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
		const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
		const weekNo = Math.ceil(
			((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
		)
		return weekNo
	}

	public static getTimeOfDayHHmm(date: Date): string {
		let timeOfDay = date.getHours().toString() + '.'
		if (date.getMinutes() < 10) {
			timeOfDay += '0'
		}
		timeOfDay += date.getMinutes().toString()
		return timeOfDay
	}

	/**
	 * Returns the difference between two date strings in the specified unit.
	 * @param start1 - The start date string.
	 * @param start2 - The end date string.
	 * @param unit - The unit of time to return the difference in ('hours', 'minutes', 'seconds', or 'milliseconds').
	 * @returns The difference between the two dates in the specified unit.
	 */
	public static calculateDateTimeDifference(
		start1: string,
		start2: string,
		unit: 'hours' | 'minutes' | 'seconds' | 'milliseconds' = 'milliseconds'
	): number {
		const differenceInMilliSeconds = Math.abs(
			new Date(start1).getTime() - new Date(start2).getTime()
		)

		switch (unit) {
			case 'hours':
				return differenceInMilliSeconds / (60 * 60 * 1000)
			case 'minutes':
				return differenceInMilliSeconds / (60 * 1000)
			case 'seconds':
				return differenceInMilliSeconds / 1000
			default:
				return differenceInMilliSeconds
		}
	}

	public static calculateTimeDifference(
		start1: string,
		start2: string,
		unit: 'hours' | 'minutes' | 'seconds' | 'milliseconds' = 'milliseconds'
	): number {
		const time1 = DateTime.fromFormat(start1, 'HH:mm').set({
			year: DateTime.now().year,
			month: DateTime.now().month,
			day: DateTime.now().day,
		})

		let time2 = DateTime.fromFormat(start2, 'HH:mm').set({
			year: DateTime.now().year,
			month: DateTime.now().month,
			day: DateTime.now().day,
		})

		if (time2 <= time1) time2 = time2.plus({ days: 1 })

		const differenceInMilliSeconds = Math.abs(
			time1.toMillis() - time2.toMillis()
		)

		switch (unit) {
			case 'hours':
				return differenceInMilliSeconds / (60 * 60 * 1000)
			case 'minutes':
				return differenceInMilliSeconds / (60 * 1000)
			case 'seconds':
				return differenceInMilliSeconds / 1000
			default:
				return differenceInMilliSeconds
		}
	}

	/*
	 * Returns the date in the format 'YYYY-MM-DDTHH:mm:ss' without timezone information.
	 * @param date - The date to format.
	 * @returns The formatted date string.
	 */
	public static formatDateWithoutTimezone(date: Date): string {
		const year = date.getFullYear()
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const day = String(date.getDate()).padStart(2, '0')
		const hours = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')
		const seconds = String(date.getSeconds()).padStart(2, '0')

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
	}

	/*
	 * Minutes to hours and minutes
	 * @param minutes - The minutes to convert
	 * @returns The formatted string
	 */
	public static minutesToHoursAndMinutes(minutes: number): string {
		const hours = Math.floor(minutes / 60)
			.toString()
			.padStart(2, '0')
		const remainingMinutes = (minutes % 60).toString().padStart(2, '0')
		return `${hours}:${remainingMinutes}`
	}

	/*
	 * Convert UTC date string to formatted local date string
	 */
	public static formatUTCStringTocalString(
		utcDateString: string | undefined | null,
		format: string
	): string {
		if (!utcDateString) {
			return ''
		}

		const dateTime = DateTime.fromISO(utcDateString, { zone: 'utc' }).setZone(
			Settings.defaultZone
		)

		//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
		return dateTime.toFormat(format)
	}

	public static formatStringTocalString(
		dateString: string | undefined | null,
		format: string
	): string {
		if (!dateString) {
			return ''
		}

		const dateTime = DateTime.fromISO(dateString)
		return dateTime.toFormat(format)
	}

	/*
	 * Convert UTC date string to local date
	 */
	public static convertUTCStringToLocalDate(
		utcDateString: string | undefined | null
	): Date {
		if (!utcDateString) {
			return new Date()
		}

		const dateTime = DateTime.fromISO(utcDateString, { zone: 'utc' }).setZone(
			Settings.defaultZone
		)

		const localDate = new Date(
			dateTime.year,
			dateTime.month - 1,
			dateTime.day,
			dateTime.hour,
			dateTime.minute,
			dateTime.second
		)
		return localDate
	}

	/**
	 * Formats a given timestamp into a specified date format.
	 *
	 * @param {string} format - The format to convert the date into.
	 * @param {Date} timestamp - The date to be formatted.
	 * @returns {string} - The formatted date.
	 */
	public static formatTimeStampToDateString(
		timestamp: number | undefined,
		format: string
	): string {
		if (!timestamp) {
			return ''
		}

		const dateTime = DateTime.fromMillis(timestamp)

		//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
		return dateTime.toFormat(format)
	}

	/**
	 *
	 * @param isoDate Formats a iso date string
	 * @param format Format
	 * @returns Formatted date
	 */
	public static formatIsoDate(
		isoDate: string | undefined | null,
		format: string
	): string {
		if (!isoDate || !format) return ''

		const dateTime = DateTime.fromISO(isoDate)
		//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
		return dateTime.toFormat(format)
	}

	/**
	 * Formats a date, datestring to HH:mm
	 *
	 */
	public static formatToTime(
		date: string | Date | undefined,
		convertUTC: boolean = true
	): string {
		if (!date) {
			return ''
		}

		const dateString = typeof date === 'string' ? date : date.toISOString()

		if (convertUTC)
			return DateUtils.formatUTCStringTocalString(dateString, 'HH:mm')
		else return DateUtils.formatStringTocalString(dateString, 'HH:mm')
	}
}
